<template>
  <Navbar/>
  <div style='width: 100%;height: 100%;position: absolute;top: 60px;min-width: 375px' class='home_content_div'>
    <div class='home_top_div'>
      <div class=''><img class='home_img_mobile' :src='topImg1'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2'></div>
    </div>
    <div class='home_top_div2'>
      <div class=''><img class='home_img_mobile' :src='topImg2in1'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2in2'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2in3'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2in4'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2in5'></div>
    </div>
    <div class='home_top_div3'>
      <div class=''><img class='home_img_mobile' :src='topImg3in4'><img class='home_img_mobile' :src='topImg3in1'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg3in2'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg3in3'></div>
    </div>
    <div class='home_top_div4'>
      <div class='' style='margin-left: 15px;'><img class='home_img_mobile' :src='topImg4in1'></div>
      <div class='homeImg-div2' style='margin-left: 7px;'><img class='home_img_mobile' :src='topImg4in2'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg4in3'></div>
      <div class='homeImg-div2' style='margin-left: 4px;'><img class='home_img_mobile' :src='topImg4in4'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg4in5'></div>
    </div>
    <div class='home_top_div4-1'>
      <div class='top_img_div4-1'><img class='home_img_mobile' :src='topImg4in6'></div>
    </div>
    <div class='home_top_div5'>
      <div>
        <br>
        <div class='div5-lRImg1'>
          <img class='div5-lRImg1-icon' :src='topImg5in1'>
          <div class='div5-flex'>
            <div class='div5-line'></div>
            <div class='div5-title-cla'>团队评估</div>
            <div class='div5-line'></div>
          </div>
          <div class='div5-content'>
            <p>团队评估：旨在帮助管理者从宏观视角洞察组织的人才结构和系统性风险，了解员工在沟通、协作、价值观等方面的整体面貌和个性差异，从而为团队组建、企业文化建设、人才选拔、人才发展提供决策参考。</p>
          </div>
        </div>

        <br>
        <div class='div5-lRImg1'>
          <img class='div5-lRImg1-icon' :src='topImg5in2'>
          <div class='div5-flex'>
            <div class='div5-line'></div>
            <div class='div5-title-cla'>团队冲突</div>
            <div class='div5-line'></div>
          </div>
          <div class='div5-content'>
            <p>团队冲突模型：旨在评估团队成员在冲突情况下的个体行为偏好，从而帮助管理者洞察和管理团队冲突的风险，并通过实践反馈更新应对冲突的“策略库”，从而掌握基于员工个体差异的管理策略。</p>
          </div>
        </div>
<!--        <div class='hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in1'></div>-->
<!--        <div class='homeImg-div2 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in2'></div>-->
      </div>
      <div>
        <br>
        <div class='div5-lRImg1'>
          <img class='div5-lRImg1-icon' :src='topImg5in3'>
          <div class='div5-flex'>
            <div class='div5-line'></div>
            <div class='div5-title-cla'>团队管理</div>
            <div class='div5-line'></div>
          </div>
          <div class='div5-content'>
            <p>团队管理：提供多种员工管理工具，帮助管理者了解员工在沟通、协作、冲突模式上的具体差异；并基于员工特点提供个性化的培训发展、人才保留和激励策略，帮助管理者更有效地管理员工。</p>
          </div>
        </div>

        <br>
        <div class='div5-lRImg1'>
          <img class='div5-lRImg1-icon' :src='topImg5in4'>
          <div class='div5-flex'>
            <div class='div5-line'></div>
            <div class='div5-title-cla'>创新加速</div>
            <div class='div5-line'></div>
          </div>
          <div class='div5-content'>
            <p>创新加速服务：通过测评、咨询和培训，帮助核心研发团队成员突破思维方式的盲区，加深彼此在沟通与协作上的理解，发挥各自的特长，降低组织内耗，优化研发的效率和效果。</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
        <br>

        <div class='div5-lRImg1'>
          <img class='div5-lRImg1-icon' :src='topImg5in5'>
          <div class='div5-flex'>
            <div class='div5-line'></div>
            <div class='div5-title-cla'>雇主品牌</div>
            <div class='div5-line'></div>
          </div>
          <div class='div5-content'>
            <p>EVP评估是一种高效的人力资源管理工具，可以对企业雇主品牌进行“定期体检”，帮助企业把有限的资源投入到吸引和留住人才的关键因素中，构建区别于竞争品牌的雇主价值主张。</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
<!--        <div class='homeImg-div2 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in3'></div>-->
<!--        <div class='homeImg-div2 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in4'></div>-->
<!--        <div class='homeImg-div2 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in5'></div>-->
      </div>
      <div>
        <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg5in6'></div>
      </div>
      <div style='    margin-top: 25px;'>
        <div class='line-div'>
<!--          <div></div>-->
          <span>认知自我</span>
          <img style='width: 30%' :src='topImg5in7'><span>选择未来</span>
<!--          <div></div>-->
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { mapState, useStore } from 'vuex'
import Navbar from '@/layout/components/appMobile/Navbar'
import Bottom from '@/layout/components/Bottom_app'
const store = useStore()
const name = ref(store.getters.userInfo.name)
const logout = () => {
  store.dispatch('user/logout')
}
const topImg1 = require('../../assets/images/home/1-1.png')
const topImg2 = require('../../assets/images/home/1-2.png')
const topImg3 = require('../../assets/images/home/1-3.png')
const topImg4 = require('../../assets/images/home/1-4.png')

const topImg2in1 = require('../../assets/images/home/2-1.png')
const topImg2in2 = require('../../assets/images/home/2-2.png')
const topImg2in3 = require('../../assets/images/home/2-3.png')
const topImg2in4 = require('../../assets/images/home/2-4.png')
const topImg2in5 = require('../../assets/images/home/2-5.png')

const topImg3in1 = require('../../assets/images/home/3-1.png')
const topImg3in2 = require('../../assets/images/home/3-2.png')
const topImg3in3 = require('../../assets/images/home/3-3.png')
const topImg3in4 = require('../../assets/images/home/3-4.png')

const topImg4in1 = require('../../assets/images/home/4-1.png')
const topImg4in2 = require('../../assets/images/home/4-2.png')
const topImg4in3 = require('../../assets/images/home/4-3.png')
const topImg4in4 = require('../../assets/images/home/4-4.png')
const topImg4in5 = require('../../assets/images/home/4-5.png')
const topImg4in6 = require('../../assets/images/home/4-6.png')

const topImg5in1 = require('../../assets/images/home/5-1.png')
const topImg5in2 = require('../../assets/images/home/5-2.png')
const topImg5in3 = require('../../assets/images/home/5-3.png')
const topImg5in4 = require('../../assets/images/home/5-4.png')
const topImg5in5 = require('../../assets/images/home/5-5.png')
const topImg5in6 = require('../../assets/images/home/5-6.png')
const topImg5in7 = require('../../assets/images/home/5-7.png')
const hovering = ref(false)

const isUserInfo = ref(false)
if (name.value !== null && name.value !== undefined && name.value !== '') {
  isUserInfo.value = true
}
// localStorage.setItem('yq_client_token', 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjUwOCwicm9sZUlkcyI6W10sImNsaWVudElwIjoiMTkyLjE2OC4wLjEwNyIsImFwcEtleSI6IndlaWxhaSIsInRva2VuIjpudWxsLCJleHAiOjE3MTA5MzExNTF9.dFZYyUnbOQlSZ9c97OmwOfwn8XLfx8VZd7hQUosOia8')
// localStorage.setItem('timeStamp', '1710844751873')

</script>

<style lang="scss" scoped>
.navbarHome {
  height: $navbarHeight;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background: $background-color-primary;
  //box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: $border-base;
  text-align: center;
  z-index: 1000;
  .newbtn{
    margin-top:9px;
    margin-left:25px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    align-items: center;
    display: inline-block;
    padding-right: 16px;
    position: fixed;
    /* top: -160px; */
    /* left: 300px; */
    right: 0;
    top: 0;

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 5px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
        line-height: 60px;

        //&:hover {
        //  background: rgba(0, 0, 0, 0.025);
        //}
      }
    }

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }

  .avatar-container{
    line-height:$navbarHeight;

    .avatar-wrapper {
      margin-top:0px !important;
    }
  }
}
.home_top_div{
  width: 100%;
  //height: 186vw;
  background: rgb(164,200,255);
  text-align: center;
  padding: 7vw 0
}
.top_img_div{
  display: inline-block;
  margin: 40px 80px;
}
.home_top_div2{
  width: 100%;
  //height: 520vw;
  background: rgb(161,223,244);
  text-align: center;
  //padding-top: 40px;
  padding: 7vw 0
}
.top_img_div2in1{
  //position: fixed;
  //margin: 0 auto;
  height: 100%;
  display: grid; /* 设置容器为grid布局 */
  place-items: center; /* 同时水平和垂直居中对齐子项目 */

}
.top_img_div2{
  display: inline-block;
  margin: 40px 80px;
}
.home_top_div3{
  background: rgb(164,200,255);
  width: 100%;
  //height: 440vw;
  text-align: center;
  padding: 7vw 0
}
.top_img_div3{
  display: inline-block;
  margin: 80px 30px;
}
.home_top_div4{
  width: 100%;
  //height: 1900px;
  background: rgb(161,223,244);
  text-align: center;
  padding: 7vw 0
}
.top_img_div4{
  display: inline-block;
  margin: 65px 80px;
}
.home_top_div4-1{
  width: 100%;
  //height: 300px;
  background: rgb(225,227,231);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15vw 0
}
.top_img_div4-1{
  //display: inline-block;
  //margin: 40px 80px;
}
.home_top_div5{
  width: 100%;
  //height: 2500px;
  background: rgb(161,223,244);
  text-align: center;
  padding: 7vw 0

}
.home_img_div5{
  display: inline-block;
  margin: 50px 80px;
}
.hover-box {
  transition: transform 0.3s;
  transform: translateY(0);
}

.hover-box:hover {
  transform: translateY(-10px);
}
.homeImg-div1{
  margin-top: 40px;
}
.homeImg-div2{
  margin-top: 70px;
}
.home_img_mobile{
  width: 95%;
}
.line-div{
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 30px;
    border-top: 1px solid white;
  }
}
.line-div span{
  color: white;
  font-size: 14px;
  margin: 0 28px;
  font-weight: bold;
}

.div5-lRImg1{
  width: 95%;
  height: 280px;
  background: #FFF;
  border-radius: 15px;
  color: #12264f;
  margin: 0 auto;
}
.div5-lRImg1-icon{
  width: 60px;
  margin-top: 15px;
}
.div5-flex{
  display: flex;align-items: center;justify-content: center;margin: 8px 0;
  margin-bottom: 15px;
}
.div5-content{
  padding: 0 26px;
  text-align: left;
  font-size: 15px;
  p{
    line-height: 25px;
  }
}
.div5-title-cla{
  padding: 0 20px;
  font-size: 20px;
  font-weight: bold;
  color: #12264f;
}
.div5-line{
  border-bottom: 1px solid #12264f;width: 92px
}
</style>
